import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { apiHelper } from "../../api/apiHelper";
import IUserProps from "../../types/IUserProps";
import { checkForCode, refreshToken, saveToken } from "./helpers";
import launchPendo from "../../helpers/launchPendo";

import { defaultScopes, msalConfig } from "./AuthConfig";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";

const profileUrl = "/api/identity/profile";

interface IProps {
  replicateSdkToken?: boolean;
  loadingComponent?: ReactNode;
  setUser: (user: IUserProps) => void;
  onMsalInitialized: (msalInstance: PublicClientApplication) => void;
}

const AuthenticationWrapper = ({
  replicateSdkToken = false,
  loadingComponent = <span>Authentication...</span>,
  setUser,
  onMsalInitialized,
  children,
}: PropsWithChildren<IProps>) => {
  const pendoKey = process.env.REACT_APP_PENDO_API_KEY;

  const [authenticated, setAuthenticated] = useState(false);
  const [msalInstance] = useState(async () => {
    const instance = new PublicClientApplication(msalConfig);
    await instance.initialize();
    onMsalInitialized(instance);
    return instance;
  });

  useEffect(() => launchPendo(pendoKey), []);

  useEffect(() => {
    msalInstance.then(async (instance) => {
      checkForCode();
      const redirectResponse = await instance.handleRedirectPromise();
      if (redirectResponse !== null) {
        let accessToken = redirectResponse.accessToken;
        saveToken(accessToken, redirectResponse.idToken, replicateSdkToken);
        await initializeUser();
      } else {
        const account = instance.getAllAccounts()[0];
        if (!account) {
          return await instance.loginRedirect({
            scopes: defaultScopes,
            state: encodeURIComponent(window.location.pathname + window.location.search)
          });
        }

        try {
          await refreshToken(instance);
          await initializeUser();
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              account: account,
              scopes: defaultScopes,
              state: encodeURIComponent(window.location.pathname + window.location.search)
          });
          } else {
            console.log(error);
          }
        }
      }
    });
  }, [replicateSdkToken]);

  const initializeUser = async () => {
    const response = await apiHelper(profileUrl, { redirect: "error" });
    const userData = await response.json();

    let isPwc = false;
    if (userData.isPwc !== undefined) isPwc = userData.isPwc === "true";
    else isPwc = userData.email.includes("pwc.com");
    setUser({
      name: userData.name,
      email: userData.email,
      isPwC: isPwc,
      role: userData.role,
    });

    // @ts-ignore
    window.pendo && window.pendo.initialize({
        visitor: {
          id: userData.uid,
          name: userData.name,
          email: userData.email,
          role: userData.role,
          internalUser: isPwc,
          country: "US",
          lineOfService: "Internal Firm Services",
          pageUrl: window.location.href,
          browserInfo: navigator.userAgent,
        },
        account: {
          id: pendoKey,
        },
      });

    setAuthenticated(true);
  };

  return <>{authenticated ? children : loadingComponent}</>;
};

export default AuthenticationWrapper;
