import { Configuration, LogLevel } from "@azure/msal-browser";

export const defaultScopes = ["openid", "profile", "email", "cloudEmail", "PwCPPI"];
const authority = process.env.REACT_APP_PWC_ID_SERVER_URL ?? 'https://login-stg.pwc.com';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_PWC_ID_C4T_CLIENT_ID ?? '',
    authority: authority + "/openam/oauth2",
    knownAuthorities: [authority],
    redirectUri: process.env.REACT_APP_PWC_ID_C4T_REDIRECT_URL ?? '/create-for-tax/signin-oidc',
    protocolMode: "OIDC",
    OIDCOptions: {
        defaultScopes: defaultScopes
    },
    onRedirectNavigate: (url) => {
      url = url.replace('response_mode=fragment', 'response_mode=query')
      window.location.replace(url);
      return false;
    }
  },
  cache: {
    cacheLocation: "sessionStorage", 
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

