import Cookie from 'universal-cookie';
import apiConfig from '../../api/apiConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { defaultScopes } from './AuthConfig';

const accessTokenCacheKey = apiConfig.ACCESS_TOKEN_KEY;
const codeParameter = 'code';
const authenticationParameters = [codeParameter, 'iss', 'state', 'client_id'];
const Cookies = new Cookie();

export const readSdkToken = () => {
  return Cookies.get(".Sdk.accessToken") || sessionStorage.getItem(".Sdk.accessToken");
}

export const createSdkToken = () => {
  if (!sessionStorage.getItem(".Sdk.accessToken")) {
    const token = sessionStorage.getItem(accessTokenCacheKey);

    if (token !== null){
      sessionStorage.setItem(".Sdk.accessToken", token);
    }
    else
      throw Error(`'${accessTokenCacheKey}' token not found in sessionStorage!`);
  }
}

export const saveToken = (accessToken: string, idToken: string, replicateToSdk: boolean = false) => {
  sessionStorage.setItem(accessTokenCacheKey, accessToken);
  sessionStorage.setItem(apiConfig.C4T_ID_TOKEN, idToken);
  if (replicateToSdk) {
    createSdkToken();
  }
}

export const refreshToken = async (msalInstance: PublicClientApplication) => {
  const accessTokenRequest = {
    account: msalInstance?.getAllAccounts()?.at(0),
    scopes: defaultScopes,
  };
  
  const accessTokenResponse = await msalInstance.acquireTokenSilent(
    accessTokenRequest
  );
  saveToken(accessTokenResponse.accessToken, accessTokenResponse.idToken, false);
}

export const checkForCode = () => {
  const currentUrl = new URL(window.location.href);
  const hash = window.location.hash?.substring(1);
  const hashParams = new URLSearchParams(hash);
  const queryParams = new URLSearchParams(window.location.search);
  
  if (queryParams.has(codeParameter)) {
    const queryParamsCopy = new URLSearchParams(window.location.search);
    queryParams.forEach((value, key) => {
      if (authenticationParameters.includes(key)) {
        hashParams.set(key, value);
        queryParamsCopy.delete(key);
      }
    });

    currentUrl.search = queryParamsCopy.toString();
    currentUrl.hash = hashParams.toString();
    window.history.pushState({}, '', currentUrl.toString());
  }
}
