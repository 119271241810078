import { useEffect, useMemo, useState } from "react";
import { Button, Search } from "@appkit4/react-components";
import PageHeader from "../../../components/PageHeader";
import { DocBotTemplate, NewDocBotTemplate } from "../../../types/Template";
import { Api } from "../../../api/apiHelper";
import { docBotTemplates as docBotTemplatesApi } from "../../../api/endpoints/docBotTemplate";
import { errorToast, successToast } from "../../../components/Toast";
import Table from "../../../components/Table";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import { createColumns } from "./helpers/createColumns";
import { DocBotTemplateForm } from "./components/DocBotTemplateForm";
import { isExistingDocBotTemplate } from "./types/typeGuards";
import { formatTableData } from "./helpers/formatTableData";
import { DocBotTemplateRow } from "./types/DocBotTemplateTableRow";
import { messages } from "../../../constants/messages";
import { DEFAULT_SEARCH_DEBOUNCE_MS } from "../../../constants/appConstants";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import styles from "./DocBotTemplates.module.scss";
import { NoResults } from "../../../components/NoResults";

export const DocBotTemplates = () => {
  const [templates, setTemplates] = useState<DocBotTemplateRow[]>([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState<
    DocBotTemplate | NewDocBotTemplate
  >();

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebouncedValue(
    searchText,
    searchText ? DEFAULT_SEARCH_DEBOUNCE_MS : 0
  );

  useEffect(() => {
    setIsLoadingTemplates(true);
    Api.get(docBotTemplatesApi)
      .then((res) => setTemplates(formatTableData(res)))
      .catch(() => errorToast({ message: "Failed to fetch DocBot Templates" }))
      .finally(() => setIsLoadingTemplates(false));
  }, []);

  const openEditTemplateModal = (id: string) =>
    setActiveTemplate(templates.find((i) => i.id === id));

  const addNewDocBotTemplate = () => {
    setActiveTemplate({
      name: "",
      docBotId: "",
    });
  };

  const confirmTemplateChanges = (
    values: DocBotTemplate | NewDocBotTemplate
  ) => {
    const isExisting = isExistingDocBotTemplate(values);
    if (isExisting) {
      Api.patch(docBotTemplatesApi, values)
        .then(() => {
          setTemplates((prev) =>
            prev.map((i) =>
              i.id === values.id ? { ...values, templates: "" } : i
            )
          );
          setActiveTemplate(undefined);
          successToast({ message: "DocBot Template has been updated" });
        })
        .catch(() =>
          errorToast({ message: "Failed to update DocBot Template" })
        );
    } else {
      Api.post(docBotTemplatesApi, values)
        .then((res) => res.json())
        .then((template) => {
          setTemplates((prev) => [template, ...prev]);
          setActiveTemplate(undefined);
          successToast({ message: "DocBot Template has been created" });
        })
        .catch(() =>
          errorToast({ message: "Failed to create DocBot Template" })
        );
    }
  };

  const columns = useMemo(
    () => createColumns(openEditTemplateModal),
    [openEditTemplateModal]
  );

  const filteredTemplates = useMemo(() => {
    if (!debouncedSearchText) {
      return templates;
    }

    return templates.filter((template) =>
      template.name.toLowerCase().includes(debouncedSearchText.toLowerCase())
    );
  }, [templates, debouncedSearchText]);

  return (
    <>
      <PageHeader
        title="DocBot Templates"
        actions={
          <div className="ap-flex ap-align-items-center ap-flex-gap-default">
            <Search
              searchType="secondary"
              placeholder={messages.common.searchPlaceholder}
              searchValue={searchText}
              onChange={setSearchText}
              onClear={() => setSearchText("")}
              className={styles.search}
            />
            <Button kind="secondary" onClick={addNewDocBotTemplate}>
              Add Template
            </Button>
          </div>
        }
      />
      <div className="ap-position-relative">
        {isLoadingTemplates && <LoadingPlaceholder overlay="parent" />}

        <Table data={filteredTemplates} columns={columns} striped condensed />

        {!filteredTemplates.length && searchText && (
          <NoResults mainMessage={messages.common.noSearchResults} />
        )}
      </div>

      {activeTemplate && (
        <DocBotTemplateForm
          activeTemplate={{
            name: activeTemplate.name,
            docBotId: activeTemplate.docBotId,
            id: isExistingDocBotTemplate(activeTemplate)
              ? activeTemplate.id
              : undefined,
          }}
          onClose={() => setActiveTemplate(undefined)}
          onSubmit={confirmTemplateChanges}
        />
      )}
    </>
  );
};
