import {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import _, { isEmpty, isEqual } from "lodash";
import {
  ColumnState,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  SortChangedEvent,
} from "ag-grid-community";
import {
  TileName,
  CountsByStatusGroup,
  DOCUMENT_TILE_ALL,
  DOCUMENT_TILE_SIGNATURES,
  GridState,
  FilterMetaDataRes,
  FilterMetaData,
} from "./types";
import TrackPresenter from "./TrackPresenter";
import createTilesView from "./createTilesView";
import {
  deactivateDocument,
  getDocumentFilterMetadataApi,
} from "../../api/endpoints/documents";
import { Api } from "../../api/apiHelper";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import TrackDetails from "./TrackDetails";
import { DocumentStatus, IDocument } from "../../types/Document";
import { UserContext } from "../../contexts/UserContext";
import { ALLOWED_TO_DOWNLOAD_ROLES } from "../../constants/allowedToDownloadRoles";
import {
  AWAITING_SIGNATURE_STATUSES,
  configureStatusFilterParams,
} from "./helpers/configureStatusFilterParams";
import { getSelectedTile } from "./helpers/getSelectedTile";
import { getColumnDefs } from "./helpers/getColumnDefs";
import copyDocumentLinkToClipboard from "../../helpers/copyDocumentLinkToClipboard";
import { successToast } from "../../components/Toast";
import useSessionStorage from "../../hooks/useSessionStorage";
import { useConfirmationModalContext } from "../../contexts/ConfirmationModalContext";
import { HOST_APP_BASE_URL } from "../../constants/hostAppBaseUrl";
import { getNavigationLink } from "./helpers/getNavigationLink";
import { useServerSideDataSource } from "./hooks/useServerSideDataSource";

export default function TrackContainer() {
  const { currentUser } = useContext(UserContext);
  const confirmationModal = useConfirmationModalContext();
  const navigate = useNavigate();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridState, setGridState] = useSessionStorage<GridState | null>(
    "c4t-track-documents-grid",
    null
  );
  const [filterMetaData, setFilterMetaData] = useState<FilterMetaData | null>(
    null
  );

  const [countsByStatusGroup, setCountsByStatusGroup] =
    useState<CountsByStatusGroup>({
      Draft: 0,
      InReview: 0,
      AwaitingSignature: 0,
      Completed: 0,
      Expired: 0,
      All: 0,
    } as CountsByStatusGroup);
  const [detailsId, setDetailsId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const tilesView = useMemo(
    () => createTilesView(countsByStatusGroup),
    [countsByStatusGroup]
  );
  const [selectedStatuses, setSelectedStatuses] = useState<DocumentStatus[]>(
    []
  );

  const initialGridState = useRef<GridState | null>(gridState);

  const searchQuery = useDebouncedValue(searchText, searchText ? 1000 : 0);

  const { serverSideDataSource, documents, isLoadingDocuments } =
    useServerSideDataSource(searchQuery, initialGridState);

  const isTemplateDownloadAllowed = ALLOWED_TO_DOWNLOAD_ROLES.some((role) =>
    currentUser?.role.includes(role)
  );

  const setTile = (id: TileName | null) => {
    const tileStatuses = configureStatusFilterParams(id);
    setSelectedStatuses(tileStatuses as DocumentStatus[]);

    let gridFilterModel = gridApi?.getFilterModel();
    gridApi?.setFilterModel({
      ...gridFilterModel,
      status: !tileStatuses?.length
        ? undefined
        : { filterType: "set", values: tileStatuses },
    });
  };

  useEffect(() => {
    Api.get(getDocumentFilterMetadataApi)
      .then((data: FilterMetaDataRes) => {
        const { engagements, subTemplates, clients, statusCounts } = data;
        setFilterMetaData((prev) => ({
          ...prev,
          subTemplates,
          engagements,
          clients,
        }));
        setCountsByStatusGroup({
          Draft: statusCounts[DocumentStatus.Draft] || 0,
          InReview: statusCounts[DocumentStatus.InReview] || 0,
          Completed: statusCounts[DocumentStatus.Completed] || 0,
          Expired: statusCounts[DocumentStatus.Expired] || 0,
          [DOCUMENT_TILE_SIGNATURES]:
            (statusCounts[DocumentStatus.AwaitingClientSignature] || 0) +
            (statusCounts[DocumentStatus.AwaitingPwCSignature] || 0) +
            (statusCounts[DocumentStatus.AwaitingPaperSignature] || 0),
          [DOCUMENT_TILE_ALL]: Object.values(statusCounts).reduce(
            (cur: number, acc: number) => cur + acc,
            0
          ),
        } as CountsByStatusGroup);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigateToDocument = (document: IDocument) => {
    navigate(
      `${HOST_APP_BASE_URL}${getNavigationLink(document)}/?documentId=${
        document.id
      }`
    );
  };

  const refreshStatusCount = (status: string, newValue: number) => {
    setCountsByStatusGroup((prev) => ({
      ...prev,
      [status]: newValue,
      All: --prev.All,
    }));
  };

  const handleDocumentRemoved = useCallback(
    (status: string) => {
      gridApi?.refreshServerSide();

      refreshStatusCount(status, --countsByStatusGroup[status]);
    },
    [gridApi, refreshStatusCount]
  );

  const handleDeleteDocument = useCallback(
    (documentId: string, status: DocumentStatus) => {
      confirmationModal
        .showConfirmation(
          "Confirmation",
          "Are you sure you want to delete the document?"
        )
        .then((isConfirmed: boolean) => {
          isConfirmed &&
            Api.delete(deactivateDocument(documentId)).then(() => {
              handleDocumentRemoved(status);

              successToast({ message: "Document was successfully deleted." });
            });
        });
    },
    [handleDocumentRemoved]
  );

  const handleGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (filterMetaData && gridApi) {
      if (!!gridState) {
        if (gridState.filterModel) {
          gridApi.setFilterModel(gridState.filterModel ?? {});
        }
        if (gridState.sortModel?.length) {
          gridApi.applyColumnState({
            state: gridState.sortModel,
          });
        }
      }
    }
  }, [filterMetaData, gridApi]);

  const handleGridFilterChanged = (filterParams: FilterChangedEvent) => {
    const gridFilter = filterParams.api.getFilterModel();

    const newFilterModel = Object.keys(gridFilter).length
      ? gridFilter
      : undefined;

    if (!isEqual(newFilterModel, initialGridState.current?.filterModel)) {
      initialGridState.current = null;
    }
    setGridState((prev) => ({
      sortModel: prev?.sortModel,
      filterModel: newFilterModel,
    }));

    const statusFilter = gridFilter?.status;
    if (
      !statusFilter ||
      !statusFilter.values?.length ||
      (statusFilter.values.length > 1 &&
        statusFilter.values.find(
          (s) => !AWAITING_SIGNATURE_STATUSES.includes(s)
        ))
    ) {
      setSelectedStatuses([]);
      return;
    }

    setSelectedStatuses(statusFilter.values);
  };

  const handleGridSortChanged = (sortParams: SortChangedEvent) => {
    let sortModel = [] as ColumnState[];
    if (sortParams.columns?.length) {
      sortModel = sortParams.columns
        .filter((c) => !!c.getSort())
        .map(
          (c) =>
            ({
              sort: c.getSort(),
              colId: c.getColId(),
            } as ColumnState)
        );
    }

    setGridState((prev) => ({
      filterModel: prev?.filterModel,
      sortModel,
    }));
    if (!isEqual(sortModel, initialGridState.current?.sortModel)) {
      initialGridState.current = null;
    }
  };

  const columns = useMemo(
    () =>
      getColumnDefs(
        setDetailsId,
        copyDocumentLinkToClipboard,
        navigateToDocument,
        handleDeleteDocument,
        filterMetaData
      ),
    [filterMetaData]
  );

  const onFiltersReset = () => {
    if (!gridApi) return;
    setGridState({
      filterModel: undefined,
      sortModel: undefined,
    });
    setSelectedStatuses([]);

    gridApi.setFilterModel(null);
    gridApi.resetColumnState();
  };

  useEffect(() => {
    //when filter-meta-data is returned after advanced-filter columns are recreated and grid loses its NoRowsOverlay-state
    //TODO: remove when all filter values are returned with separate api
    if (gridApi && !isLoadingDocuments && !documents.rows.length) {
      gridApi.showNoRowsOverlay();
    }
  }, [columns, isLoadingDocuments]);

  return (
    <>
      <TrackPresenter
        columns={columns}
        tilesView={tilesView}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedTile={getSelectedTile(selectedStatuses)}
        setSelectedTile={setTile}
        loading={isLoadingDocuments}
        legacyLink={undefined}
        isTemplateDownloadAllowed={isTemplateDownloadAllowed}
        onGridReady={handleGridReady}
        onFilterChanged={handleGridFilterChanged}
        onGridSortChanged={handleGridSortChanged}
        serverSideDataSource={serverSideDataSource}
        count={documents.count}
        isFilterApplied={!isEmpty(gridState?.filterModel)}
        onFiltersReset={onFiltersReset}
      />
      <TrackDetails
        id={detailsId}
        rows={documents.rows}
        close={() => setDetailsId(null)}
        handleDocumentRemove={handleDocumentRemoved}
      />
    </>
  );
}
