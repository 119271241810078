import { MutableRefObject, useMemo, useRef, useState } from "react";
import { IServerSideGetRowsParams } from "ag-grid-community";
import { isEmpty } from "lodash";
import { Api } from "../../../api/apiHelper";
import { getAGDocumentsApi } from "../../../api/endpoints/documents";
import { GridState } from "../types";

type Documents = {
  rows: any[];
  count: number;
};

export const useServerSideDataSource = (
  searchQuery: string,
  initialGridState: MutableRefObject<GridState | null>
) => {
  const [documents, setDocuments] = useState<Documents>({ rows: [], count: 0 });
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const collection = useRef(new Map());

  const serverSideDataSource = useMemo(
    () => ({
      getRows: async (params: IServerSideGetRowsParams) => {
        params.api.hideOverlay();

        const requestParams =
          !initialGridState.current ||
          (initialGridState.current && isEmpty(initialGridState?.current))
            ? { ...params.request, quickFilterText: searchQuery }
            : {
                ...params.request,
                filterModel: initialGridState?.current?.filterModel || {},
                sortModel: initialGridState?.current?.sortModel || [],
                quickFilterText: searchQuery,
              };

        const successCallback = (data: { items: any[]; total: number }) => {
          setDocuments({ rows: data.items, count: data.total });
          !data.items.length
            ? params.api.showNoRowsOverlay()
            : params.api.hideOverlay();
          params.success({ rowData: data.items, rowCount: data.total });
        };

        const requestKey = JSON.stringify(requestParams);

        setIsLoadingDocuments(true);
        if (collection.current.has(requestKey)) {
          collection.current
            .get(requestKey)
            .then((data) => successCallback(data))
            .catch(() => {
              collection.current.delete(requestKey);
              params.fail();
            })
            .finally(() => setIsLoadingDocuments(false));
        } else {
          collection.current.set(
            requestKey,
            Api.post(getAGDocumentsApi(), requestParams)
              .then((res) => res.json())
              .then((data) => {
                successCallback(data);
                return data;
              })
              .catch(() => params.fail())
              .finally(() => setIsLoadingDocuments(false))
          );
        }
      },
    }),
    [searchQuery]
  );

  return {
    serverSideDataSource,
    documents,
    isLoadingDocuments,
  };
};
