import {
  ColDef,
  SetFilterValuesFuncParams,
  ValueFormatterParams,
} from "ag-grid-enterprise";
import {
  ActionsRenderer,
  CheckMarkRenderer,
  EngagementRenderer,
  StatusRenderer,
} from "../components/renderers";
import { DocumentStatus, IDocument } from "../../../types/Document";
import { DOCUMENT_STATUS_NAMES } from "../../../constants/documentStatusName";
import { dateFormatter } from "../../../components/Grid/helpers/formaters";
import { isStandaloneFormatter } from "./gridFormatters";
import { STATUS_FILTER_OPTIONS } from "../constants/statusFilterOptions";
import { FilterMetaData } from "../types";
import { Api } from "../../../api/apiHelper";
import {
  detDocumentUpdatedByFilterMetaDataApi,
  getDocumentCreatedByFilterMetadataApi,
  getDocumentEntitiesFilterMetadataApi,
  getDocumentOFROReviewersFilterMetadataApi,
  getDocumentPwCSignersFilterMetadataApi,
} from "../../../api/endpoints/documents";
import { Entity } from "../../../types/Entity";

export const getColumnDefs = (
  onOpenDetailsClick: (documentId: string) => void,
  onGetDocumentLink: (documentId: string) => void,
  onNavigateToDocument: (document: IDocument) => void,
  onDeleteDocument: (documentId: string, status: DocumentStatus) => void,
  filterMetaData: FilterMetaData | null
): ColDef[] => {
  return [
    {
      colId: "clientName",
      field: "clientName",
      headerName: "Client Name",
      minWidth: 250,
      filter: "agSetColumnFilter",
      filterParams: {
        values: filterMetaData?.clients?.map((e) => e.name) || [],
      },
    },
    {
      colId: "engagementName",
      field: "engagementName",
      headerName: "Engagement",
      minWidth: 200,
      lockVisible: true,
      cellRenderer: EngagementRenderer,
      cellRendererParams: {
        onNavigate: onOpenDetailsClick,
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: filterMetaData?.engagements?.map((e) => e.name) || [],
      },
    },
    {
      colId: "entityName",
      field: "entityName",
      headerName: "Entity / Individual",
      minWidth: 200,
      filterParams: {
        values: (params: SetFilterValuesFuncParams) =>
          Api.get(getDocumentEntitiesFilterMetadataApi).then((res) =>
            params.success(res)
          ),
      },
    },
    {
      colId: "templateName",
      field: "templateName",
      headerName: "Template",
      minWidth: 350,
      sortable: false,
      filterParams: {
        values: filterMetaData?.subTemplates?.map((e) => e.name) || [],
      },
    },
    {
      colId: "createdBy",
      field: "createdBy",
      headerName: "Created By",
      minWidth: 200,
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          Api.get(getDocumentCreatedByFilterMetadataApi).then((res) =>
            params.success(res)
          );
        },
      },
    },
    {
      colId: "pwcSignerName",
      field: "pwCSignerName",
      headerName: "PwC Signer",
      minWidth: 200,
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          Api.get(getDocumentPwCSignersFilterMetadataApi).then((res) => {
            const formattedRes = res.map((i: Entity) => i.name);
            params.success(formattedRes);
          });
        },
      },
    },
    {
      colId: "ofroReviewer",
      field: "ofroReviewer",
      headerName: "OFRO Reviewer",
      minWidth: 200,
      sortable: false,
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          Api.get(getDocumentOFROReviewersFilterMetadataApi).then((res) => {
            const formattedRes = res.map((i: Entity) => i.name);
            params.success(formattedRes);
          });
        },
      },
    },
    {
      colId: "status",
      field: "status",
      headerName: "Status",
      minWidth: 300,
      valueFormatter: (params: ValueFormatterParams) =>
        params?.value ? DOCUMENT_STATUS_NAMES[params?.value] : "",
      cellRenderer: StatusRenderer,
      filter: "agSetColumnFilter",
      filterParams: {
        values: STATUS_FILTER_OPTIONS,
        valueFormatter: (params: ValueFormatterParams) =>
          params?.value ? DOCUMENT_STATUS_NAMES[params?.value] : "",
      },
    },
    {
      colId: "modifiedDate",
      field: "modifiedDate",
      headerName: "Updated Date",
      minWidth: 200,
      valueFormatter: dateFormatter,
      filter: "agDateColumnFilter",
    },
    {
      colId: "uploadedToTaxDocs",
      field: "uploadedToTaxDocs",
      headerName: "Filed to Tax Docs",
      minWidth: 210,
      cellClass: "ap-justify-content-center",
      cellRenderer: CheckMarkRenderer,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        valueFormatter: (params: ValueFormatterParams) =>
          params?.value ? "Yes" : "No",
      },
    },
    {
      colId: "actions",
      field: "id",
      headerName: "Actions",
      sortable: false,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      suppressSizeToFit: true,
      suppressHeaderFilterButton: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      suppressHeaderMenuButton: true,
      cellClass: "action-cell ap-justify-content-center",
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        onGetDocumentLink,
        onDeleteDocument,
        onNavigateToDocument,
      },
    },
    {
      colId: "documentId",
      field: "id",
      headerName: "Document Id",
      hide: true,
    },
    {
      colId: "modifiedBy",
      field: "modifiedBy",
      headerName: "Updated By",
      hide: true,
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          Api.get(detDocumentUpdatedByFilterMetaDataApi).then((res) =>
            params.success(res)
          );
        },
      },
    },
    {
      colId: "engagementIsStandalone",
      field: "engagementIsStandalone",
      headerName: "Standalone or EC Engagement",
      valueFormatter: isStandaloneFormatter,
      hide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        valueFormatter: isStandaloneFormatter,
      },
    },
    {
      colId: "createdDate",
      field: "createdDate",
      headerName: "Created Date",
      minWidth: 200,
      valueFormatter: dateFormatter,
      hide: true,
      filter: "agDateColumnFilter",
    },
    {
      colId: "docusignEnvelopeId",
      field: "docuSignEnvelopeId",
      headerName: "Docusign Envelope Id",
      hide: true,
      filter: false,
    },
    {
      colId: "docuSignEmailSubject",
      field: "docuSignEmailSubject",
      headerName: "Docusign Subject",
      hide: true,
      filter: false,
    },
  ];
};
