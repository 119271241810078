import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Search, Upload } from "@appkit4/react-components";
import PageHeader from "../../../components/PageHeader";
import Table from "../../../components/Table";
import { TemplateRow } from "../../../types/Template";
import { Api } from "../../../api/apiHelper";
import {
  handleTemplateById,
  templates as templatesApi,
} from "../../../api/endpoints/templates";
import { errorToast, successToast } from "../../../components/Toast";
import { useConfirmationModalContext } from "../../../contexts/ConfirmationModalContext";
import { createTemplateColumns } from "./helpers/createTemplateColumns";
import { ROUTES } from "../../../constants/routes";
import { importTemplateApi } from "../../../api/endpoints/admin";
import { AppkitUploadedFile } from "../../../types/AppkitUploadedFile";
import { messages } from "../../../constants/messages";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import { DEFAULT_SEARCH_DEBOUNCE_MS } from "../../../constants/appConstants";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import { NoResults } from "../../../components/NoResults";
import styles from "./Template.module.scss";

export const Templates = () => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<TemplateRow[]>([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebouncedValue(
    searchText,
    searchText ? DEFAULT_SEARCH_DEBOUNCE_MS : 0
  );

  const { showConfirmation } = useConfirmationModalContext();

  useEffect(() => {
    loadTemplates();
  }, []);

  const addTemplate = () => {
    navigate(ROUTES.CREATE_TEMPLATE.path);
  };

  const importTemplate = (files: AppkitUploadedFile[]) => {
    const formData = new FormData();
    formData.append("file", files[0].originFile);

    Api.postFile(importTemplateApi, formData)
      .then(() => loadTemplates())
      .catch(() => errorToast({ message: `Failed to import the template.` }))
      .finally(() => setIsImportModalOpen(false));
  };

  const editTemplate = (row: TemplateRow) => {
    navigate(`${row.id}`);
  };

  const loadTemplates = () => {
    setIsLoadingTemplates(true);
    Api.get(templatesApi)
      .then((res) => setTemplates(res))
      .catch(() => errorToast({ message: "Failed to load templates" }))
      .finally(() => {
        setIsLoadingTemplates(false);
      });
  };

  const deleteTemplate = (row: TemplateRow) => {
    showConfirmation(
      "Delete Template",
      `Are you sure you want to delete ${row.name} template?`
    ).then((isConfirmed) => {
      if (!isConfirmed) return;
      Api.delete(handleTemplateById(row.id), {})
        .then(() => {
          setTemplates((prev) => prev.filter((i) => i.id !== row.id));
          successToast({ message: "Template has been deleted" });
        })
        .catch((error) =>
          errorToast({ message: `Failed to delete the template. ${error}` })
        );
    });
  };

  const filteredTemplates = useMemo(() => {
    if (!debouncedSearchText) {
      return templates;
    }

    return templates.filter((template) =>
      template.name.toLowerCase().includes(debouncedSearchText.toLowerCase())
    );
  }, [templates, debouncedSearchText]);

  const columns = useMemo(
    () => createTemplateColumns(editTemplate, deleteTemplate),
    [templates, editTemplate, deleteTemplate]
  );

  return (
    <>
      <PageHeader
        title="Templates & SubTemplates"
        actions={
          <div className="ap-flex ap-align-items-center ap-flex-gap-default">
            <Search
              searchType="secondary"
              placeholder={messages.common.searchPlaceholder}
              searchValue={searchText}
              onChange={setSearchText}
              onClear={() => setSearchText("")}
              className={styles.search}
            />
            <Button kind="primary" onClick={addTemplate}>
              Add Template
            </Button>
            <Button kind="secondary" onClick={() => setIsImportModalOpen(true)}>
              Import
            </Button>
          </div>
        }
      />

      <div className="ap-position-relative">
        {isLoadingTemplates && <LoadingPlaceholder overlay="parent" />}

        <Table data={filteredTemplates} columns={columns} striped condensed />

        {!filteredTemplates.length && searchText && (
          <NoResults mainMessage={messages.common.noSearchResults} />
        )}
      </div>

      <Modal
        title="Import Template"
        onCancel={() => setIsImportModalOpen(false)}
        visible={isImportModalOpen}
      >
        <Upload
          multiple={false}
          autoUpload={false}
          onUpload={importTemplate}
          acceptFileType=".json"
          uploadInstruction="You can upload json file. The max file size is 10mb."
          uploadTitle="Upload your file"
          maxFileSize={10 * 1024 * 1024}
          config={{ trigger: true }}
        ></Upload>
      </Modal>
    </>
  );
};
